.App {
  text-align: center;
  display: flex;
  background-color: #282c34;  
  color: white;
  position: absolute;
  top: 0;bottom: 0;right: 0;left: 0;
}

.auth-subform-button {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 10px;  
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin: 10px 0;
}

.auth-form-button {
  background-color: #1abc9c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;  
  cursor: pointer;
  font-weight: bold;
  margin: 10px 0;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

.auth-form-button.logout {
  background-color: #A41034;
}

.trademark {
  position: relative;
  display: inline-block;
}

.registered {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 80%;
}

.apple-pay {
  background-image: url('/src/images/apple-pay-icon2.png');
  background-size: 72px 72px;
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 20px; /* adjust as needed */
  color: #ffffff; /* set the button text color */
  background-color: green;
}

.wechat-pay {
  background-image: url('/src/images/wechat-pay-icon5.png');
  background-size: 36px 36px;
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 20px; /* adjust as needed */
  color: #ffffff; /* set the button text color */
  background-color: green;
}

.apli-pay {
  background-image: url('/src/images/ali-pay-icon2.png');
  background-size: 36px 36px;
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 20px; /* adjust as needed */
  color: #ffffff; /* set the button text color */
  background-color: green;
}

.auth-form-button.contribute {
  background-color:blue;
}

.auth-form-button.register {
  background-color:blue;
}

.auth-form-button.login {
  background-color: green;
}

.auth-form-button.forgot {
  background-color: #e74c3c;
}

.auth-form-button.reset {
  background-color: blue;
}

.auth-form-button.verifyemail {
  background-color: dark rgb(197, 238, 130);
}

.auth-form-button.comment {
  background-color: green;
}

.auth-form-button.viewlog {
  background-color: #A41034;
}

.auth-form-button.downloadlog {
  background-color: #A41034;
}

.auth-form-button.apli-pay {
  background-color: #2596be;
}


h4 {
  margin-top: 20px; 
  text-align: left;
  padding: 0.25rem 0;

}

h3 {
  margin-top: -50px; /* or any other value that works for your design */
}

.auth-form-container, .login-form, .register-form, .forgot-form, .reset-form, .verify-form, .payment-form {
  display: flex;
  flex-direction: column;
  margin-top: 25px;  
}

/* .payment-form {
  display: flex;
  flex-direction: column;
  margin-top: -5px;  
} */

.table-form {
  border-collapse: collapse;
}

.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: transform 0.3s ease-out;
}

.slide-away {
  transform: translateX(-100%);
}


.table-container {
  height: 500px; /* set the height to your desired value */
  overflow-y: scroll;
}

.table-form {
  border-collapse: collapse;
  width: 100%;
}

.table-form td {
  border: 1px solid #ddd;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left : 6px;
  padding-right : 6px;
  text-align: left;
  font-size: small;
}

.table-form th {
  background-color: blue;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 0.1px solid #ddd;
  padding: 4px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: green;
}

tr:hover {
  background-color: magenta;
}

th {
  font-size: smaller;
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: #4CAF50;
  color: white;
  border: 1px solid #ddd;
}



@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }  
}

.success {
  font-size: 14px;    
  background-color: green;
  color: white;
  margin-top: -10px;    

  border-radius: 10px;
  margin: 0.5rem;
  padding: 0.5rem;  
}

.error {
  font-size: 14px;    
  background-color: #f55321;
  color: white;
  margin-top: -10px;    

  border-radius: 10px;
  margin: 0.5rem;
  padding: 0.5rem;    
}

.warning {
  font-size: 14px;    
  background-color: yellow;
  color: red;
  font-weight: bold;  
  margin-top: -10px;    
  border-radius: 10px;
  margin: 0.5rem;
  padding: 0.5rem;    
}

label {
  text-align: left;
  padding: 0.25rem 0;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

input {
  font-size: 14px;  
  margin: 0.25rem 0;
  padding: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  border-radius: 10px;
}

.input2 {
  font-size: 14px;  
  margin: 0.25rem 0;
  padding: 0rem;  
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  border-radius: 2px;
}

notused {
  background-color: #f55e3038;   
}

button {
  border: none;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
  font-size: 12px;
  padding: 3px 10px;
}

button.download-button {
  background-color: maroon;
  color: white;
}

button.edit {
  background-color: #2196f3;
  color: white;
}

button.cancel {
  background-color: #f44336;
  color: white;
}

button.save {
  background-color: blue;
  color: white;
}

.payments-btn {
  font-size: small;
  padding: 5px;  
  background: none;
  color: navajowhite;
  text-decoration: underline;
}

.wechatpay-btn {
  font: bold;  
  padding: 5px;  
  background: none;
  color: green;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 16px;  
}

.alipay-btn {
  font: bold;
  padding: 5px;  
  background: none;
  color: green;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 16px;  
}

.apple-btn {
  font: bold;
  padding: 5px;  
  background: none;
  color: green;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 16px;  
}

.support-btn {
  padding: 5px;  
  background: none;
  color: greenyellow;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

.disclaimer-link-btn {
  padding: 5px;  
  background: none;
  color: #A41034;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
  margin-left: -300px; /* Adjust this value to move the button to the desired distance */  
}

.Spintrol-link-btn {
  border: none;
  border-radius: 5px;

  position: relative;
  padding-left: 45px; /* adjust this value to make room for the icon */
  padding-right: 10px; /* add this value to move the text to the right */  
  color: blue; /* Change this to set the color of the text */  
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 16px;  
  font-weight: bold; /* Change this to set the font-weight of the text */  
  padding-top: 10px;    
  padding-bottom: 10px;    
}

.Spintrol-link-btn .icon {
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  width: 45px; /* adjust this value to set the size of the icon */
  height: 33px;
  background-image: url('/src/images/spintrol-logo1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: whitesmoke; /* change this to set the background color of the icon */
  z-index: 1;
}

.close-button {
  position: absolute;
  top: 115px;
  right: auto;
  cursor: pointer;
  font-size: 20px;
  color: #fff; /* set the text color */
  background-color: #333; /* set the background color */
}

.wechat-sale-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  font-size: 14px;  
  font-family: "Open Sans", Arial, sans-serif;  
}

.wechat-sale-title {
  text-align: center;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;  
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.HarvardCrimson-link-btn {
  padding: 5px;  
  background: none;
  color: #A41034;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

.link-btn-wechatgroup {
  padding: 5px;  
  background: none;
  color: greenyellow;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

.link-btn {
  padding: 5px;  
  background: none;
  color: magenta;
  text-decoration: underline;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

.sidemenu {
  width: 250px;
  padding: 10px;
  background-color: #202123;  
}

.sidemenu-deployment-domain {
  margin-top: -35px;
  margin-left: 5px;
  width: 275px;
  padding: 10px;
  background-color: #202123;
  text-align: left;  
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

.auth-form-welcome {
  color: white;
  margin-top: -10px;    
  border-radius: 10px;
  margin: 0.5rem;
  padding: 0.5rem;  
}

.sidemenu-current-user {
  width: 275px;
  padding: 10px;
  background-color: #202123;
  text-align: left;  
}

.side-menu-newchat {
  margin-top: -30px;      
  caret-color: #202123;  
  cursor: pointer;
  font-size: small;
  padding: 15px;
  border: none;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.side-menu-dots {
  margin-top: -30px;      
  caret-color: #202123;  
  cursor: pointer;
  font-size: small;
  padding: 25px;
  border: none;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.image-typing-effect {
  animation-name: image-appear;
  animation-duration: 30s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes image-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.input-with-cursor {
  caret-color: auto;
}

.select-func {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #ccc; 
  background-color: blue;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px 12px;
  box-shadow: none;
  transition: all 0.15s ease-in-out;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M7 10l5 5 5-5z"/></svg>');  
  background-repeat: no-repeat;
  background-position: right -5px center;
  width: 210px;
}

.select-func option {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #333;
  background-color: #fff;
}

.select-size {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #ccc; 
  background-color: blue;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px 12px;
  box-shadow: none;
  transition: all 0.15s ease-in-out;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M7 10l5 5 5-5z"/></svg>');  
  background-repeat: no-repeat;
  background-position: right -5px center;
  width: 120px;
}

.select-size option {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #333;
  background-color: #fff;
}

.select-model {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #ccc; 
  background-color: blue;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px 12px;
  box-shadow: none;
  transition: all 0.15s ease-in-out;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M7 10l5 5 5-5z"/></svg>');  
  background-repeat: no-repeat;
  background-position: right -5px center;
  width: 228px;
}

.select-model option {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #333;
  background-color: #fff;
}

.select-temperature {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #ccc; 
  background-color: blue;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px 12px;
  box-shadow: none;
  transition: all 0.15s ease-in-out;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M7 10l5 5 5-5z"/></svg>');  
  background-repeat: no-repeat;
  background-position: right -5px center;
  width: 60px;
}

.select-temperature option {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #333;
  background-color: #fff;
}

.side-menu-button {
  cursor: pointer;  
  padding: 15px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
  font-family: "Open Sans", Arial, sans-serif;  
  font-size: 14px;  
}

.side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.side-menu-button span {
  padding-left: 6px;
  padding-right: 12px;
}

.chatbox {
  flex: 1;
  border: 1px solid white;
  background-color: #343541;
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-log {  
  text-align: left;

  overflow-y: auto; /* enable vertical scrolling */
  max-height: calc(100% - 100px); /* limit the height of the chat-log to 50px less than the parent container */
}

.regenerate-holder {
  margin-top: 200px;
  text-align: center;
}

.regenerate-holder {
  position: absolute;
  bottom: 95px; /* Adjust this value to control the vertical position of the button */
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%);
  text-align: center;
  z-index: 1; /* Make sure the button is on top of other elements */
}
.regenerate-holder {
  display: none;
}
.regenerate-btn {
  display: none;
}

.chat-input-holder {
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;  right: 0;
  margin-top: auto; /* push the chat-input-holder to the bottom of the parent container */  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chat-input-textarea {
  background-color: #40414f;  
  resize: none;
  overflow: auto;
  height: 40px; /* set initial height */
  min-height: 40px; /* set minimum height */
  width: 90%;
  padding: 12px;
  border: 1px solid gray;
  border-radius: 5px;
  color: white;  
  outline: none;
  font-size: 16px;
  border:none;  
  line-height: 1.5;
  box-shadow: 0 8px 0 0 rgba(0,0,0,0.25);  
}

/* CSS */
.chat-input-container {
  position: relative;
}

.chat-input-textarea {
  width: 70%;
  padding-right: 36px; /* make space for the submit button */
  caret-color: auto;  
}

.submit-btn {
  position: relative;
  top: -40px;
  right: 60px;
  width: 36px;
  height: 36px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.icon {
  font-size: 30px;
  transform: rotate(45deg);  
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-message.chatgpt {
  background-color: #444654;  
}

.chat-message-center {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.avatar {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.avatar.chatgpt {
  background: #0da37f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.message {
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.models {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.models select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: none;
  appearance: none;
}

.models select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #007bff;
  border-color: #007bff;
}

.models select option {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: white;
  color: #333;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
