  .ad {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: fit-content;
    background-color: gray;
    color: white;
    font-size: small;
    font-weight: lighter;
    text-align: center;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .ad.show {
    opacity: 1;
  }
  
  .ad.hide {
    opacity: 0;
  }
  